code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}

.icon {
  max-height: 70px; 
  max-width: 70px;
}

.painting {
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
}

.center-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

body, html {
  height: 100%;
  }



.py-code{
  color: rgb(27, 0, 27);
  font-weight:bolder; 
  font-style:italic;
  font-size:14px;
  padding-top: 10px;
}

.py-def {
  color: rgb(185, 3, 155);
}

.py-hwf{
  color: rgb(14, 4, 155);
}

.py-none{
  color: rgb(185, 3, 155);
}

.py-print {
  color: rgb(60, 0, 139);
}

.py-hwt {
  color: darkgreen;
}

h2.resume_title {
  color: darkslategrey;
}


.img-thumbnail {
  max-width: 80%;
  height: auto;
}




.wrapper {
  column-count: 1;
  column-gap: 50px;
  padding: 50px;
}

.personal-photo {
  float: left;
  width: 60%;
  shape-outside: url(assets/personal_image.jpg);
  shape-margin:20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

@media (max-width: 700px) {
  .personal-photo {
    float: left;
    width: 100%;
    shape-outside: url(assets/personal_image.jpg);
    shape-margin:20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

/* 
body {
  background-attachment:fixed;
  background-image: url(components/home3.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: initial;
} */

/* margin: 0;
background-attachment:fixed;
background-size: cover;
background-image: url(components/home3.jpg);
background-position: center;
background-repeat: initial; */



.navbar:hover {
  background-color: #3729758c;
  transition-duration: 0.4s;

}

.owner-name {
  color:#edddf3;
}

.owner-name.withhover:hover  {
  color:#ce98e4;
  transition-duration: 0.2s;
}


.nav-link {
  color:#dab0eb;
}

.nav-link:hover {
  color:#f3f3f3;
  transition-duration: 0.1s;

}

.technologies {
  background-color: #ffffff;
  margin: auto;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.row-resume {
  background-color: #ffffff;
}

.resume-margine {
  margin: 2px;
}

.resume {
  background-color: rgba(253, 238, 238, 0.5);
  padding-top: 1%;
  padding-bottom: 1%;
  padding-right: 4%;
  padding-left: 4%;
}

.projects {
  background-color: rgba(253, 238, 238, 0.5);
  padding-top: 1%;
  padding-bottom: 1%;
  padding-right: 4%;
  padding-left: 4%;
}
.project-image {
  border: 1px solid rgb(27, 27, 27);
}
.project {
  background-color: #f3f3f3;
}

.art {
  background-color: rgba(31, 37, 56, 0.5);
  padding-top: 1%;
  padding-bottom: 1%;
  padding-right: 4%;
  padding-left: 4%;
}

.paintings {
  margin: auto;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

}

.about {
  background-color: rgba(253, 238, 238, 0.7);
}

.home {
  background-color: rgba(254, 240, 255, 0.541);
}


.home-div {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment:fixed;
}



.painting-div {
  position:relative;
  height: 100%;
  width: 100%;
}


.container {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.connection {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 2%;

}
.connections {
  padding-top: 5%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.navigation {
  width: 100%;
  max-width: 900px;
  margin: auto;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 1%;
  padding-right: 1%;
}

@media (max-width: 700px) {
  .navigation {
    width: 100%;
    max-width: 900px;
    margin: auto;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }
}

.navbar-nav {
  display: inline-block;
  width:100%;
}

.header-menu { 
  display: flex;
  flex-wrap: wrap-reverse;
}
.nav-item {
  display: inline-block;
  width: initial;
  white-space: nowrap;
  padding: 10px;
  align-items: flex-end;
}

/* 
.bg {
  margin: 0;
  position: absolute;
  background-attachment:fixed;
  background-size: cover;
  background-image: url(components/home3.jpg);
  width: 100vw;
  height: 100vh;

} */

/* .bg { 
  background: url(components/home3.jpg) no-repeat 
    center center scroll; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */


/* body {
  background: url(components/home3.jpg) no-repeat 
  center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
} */

body:after{
  content:"";
  position:fixed; /* stretch a fixed position to the whole screen */
  top:0;
  height:100vh; /* fix for mobile browser address bar appearing disappearing */
  left:0;
  right:0;
  z-index:-1; /* needed to keep in the background */
  background: url(components/home3.jpg) center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}